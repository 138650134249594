import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import MarketingPage from './pages/landingPage';
import './App.css';

const App: React.FC = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const logoutUri = process.env.REACT_APP_AUTH0_LOGOUT_URI!;
  const inovaUri = process.env.REACT_APP_INOVA_URI!;

  return (
    <div className="app-container">
      {!isAuthenticated ? (
        <MarketingPage loginWithRedirect={loginWithRedirect} />
      ) : (
        <>
          <button
            className="logout-button"
            onClick={() => logout({ logoutParams: { returnTo: logoutUri } })}
          >
            Log out
          </button>
          <iframe
            title="Inovacon Spaces"
            src={inovaUri}
            style={{ border: 'none' }}
            className="full-screen-iframe"
          ></iframe>
        </>
      )}
    </div>
  );
};

export default App;
