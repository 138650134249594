import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import { Box } from '@mui/material';

interface MarketingPageProps {
    loginWithRedirect: () => void;
    disableCustomTheme?: boolean;
}

export default function MarketingPage(props: MarketingPageProps) {
    const { loginWithRedirect, disableCustomTheme } = props;

    return (
        <AppTheme disableCustomTheme={disableCustomTheme}>
            <CssBaseline enableColorScheme />
            <AppAppBar loginWithRedirect={loginWithRedirect} />
            <Hero />
            <div>
                <LogoCollection />
                <Box id="features-section">
                    <Features />
                </Box>
                <Divider />
                <Box id="testimonials-section">
                    <Testimonials />
                </Box>
                <Divider />
                <Box id="highlights-section">
                    <Highlights />
                </Box>
                <Divider />
                <Box id="pricing-section">
                    <Pricing />
                </Box>
                <Divider />
                <Box id="faq-section">
                    <FAQ />
                </Box>
                <Divider />
                <Footer />
            </div>
        </AppTheme>
    );
}
