import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InovaconIcon from './InovaconIcon';
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
  transition: 'transform 0.5s ease-in-out',
}));

interface AppAppBarProps {
  loginWithRedirect?: () => void;
  logout?: () => void;
  isAuthenticated?: boolean;
}

export default function AppAppBar({ loginWithRedirect, logout, isAuthenticated }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);
  const [showAppBar, setShowAppBar] = React.useState(true);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollThreshold = pageHeight * 0.05;
      setShowAppBar(scrollPosition < scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
        transform: showAppBar ? 'translateY(0)' : 'translateY(-150%)',
        transition: 'transform 0.5s ease-in-out',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', px: 0 }}> <InovaconIcon />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: 2 }}> 
  <Button
    variant="text"
    color="info"
    size="small"
    onClick={() => {
      const section = document.getElementById('features-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    Features
  </Button>
  <Button
    variant="text"
    color="info"
    size="small"
    onClick={() => {
      const section = document.getElementById('testimonials-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    Testimonials
  </Button>
  <Button
    variant="text"
    color="info"
    size="small"
    onClick={() => {
      const section = document.getElementById('highlights-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    Highlights
  </Button>
  <Button
    variant="text"
    color="info"
    size="small"
    onClick={() => {
      const section = document.getElementById('pricing-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    Pricing
  </Button>
  <Button
    variant="text"
    color="info"
    size="small"
    onClick={() => {
      const section = document.getElementById('faq-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
    sx={{ minWidth: 0 }}
  >
    FAQ
  </Button>
  <Button
    variant="text"
    color="info"
    size="small"
    sx={{ minWidth: 0 }}
    onClick={() => {
      const section = document.getElementById('blog-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }}
  >
    Blog
  </Button>
</Box>

          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            {isAuthenticated ? (
              <Button color="primary" variant="text" size="small" onClick={logout}>
                Logout
              </Button>
            ) : (
              loginWithRedirect && (
                <>
                  <Button color="primary" variant="text" size="small" onClick={loginWithRedirect}>
                    Sign in
                  </Button>
                  <Button color="primary" variant="contained" size="small" onClick={loginWithRedirect} sx={{ whiteSpace: 'nowrap' }}>Sign up</Button>
                </>
              )
            )}
            <ColorModeIconDropdown />
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
            <ColorModeIconDropdown size="medium" />
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>

                <MenuItem>Features</MenuItem>
                <MenuItem>Testimonials</MenuItem>
                <MenuItem>Highlights</MenuItem>
                <MenuItem>Pricing</MenuItem>
                <MenuItem>FAQ</MenuItem>
                <MenuItem>Blog</MenuItem>
                <Divider sx={{ my: 3 }} />
                {loginWithRedirect && (
                  <>
                    <MenuItem>
                      <Button color="primary" variant="contained" fullWidth onClick={loginWithRedirect}>
                        Sign up
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button color="primary" variant="outlined" fullWidth onClick={loginWithRedirect}>
                        Sign in
                      </Button>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
